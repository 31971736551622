*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial;
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');

$active-color: #AA1519;

body{
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
    "header"
    "main"
    "footer";
}

main{
    grid-area: main;
    background-color: #ffffff;
    padding: 1rem;
}