footer .underbar {
  grid-area: footer;
  height: 30px;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  gap: 2rem;
}

footer .underbar::before {
  content: "";
  position: absolute;
  bottom: 100%;
  width: 100%;
  height: 3px;
  background: linear-gradient(130deg, #ff7a18, #af002d 41.07%, #319197 76.05%);
}

footer .underbar p {
  color: #ffffff;
}

footer .underbar a {
  color: #ffffff;
  text-decoration: none;
}

footer .underbar a:hover {
  color: #AA1519;
}
