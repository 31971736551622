$active-color: #AA1519;

.Errorpage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem;
    img{
        width: 100%;
        max-width: 650px;
    }
    h2{
        span{
            background-color: black;
            color: #fff;
            padding: 0 0.2rem;
        }
    }
    div{
        display: flex;
        gap: 4rem;
        flex-wrap: wrap;
        a{
            background-color: #ffffff;
            color: black;
            text-decoration: none;
            font-weight: 600;
            position: relative;
            padding: 0.2rem 0;
            margin: 1rem 0;
            cursor: pointer;
            &:hover{
                --p: 100%;
                i{
                    color: black;
                    &::before{
                        content: "";
                    }
                }
            }
            &:first-child{
                i{
                    padding: 0 0 0 .4rem;
                }
                &:hover{
                    --p: 100%;
                    i{
                        padding: 0;
                        color: black;
                        &::before{
                            content: "";
                        }
                    }
                }
            }
            &::before{
                content: "";
                position: absolute;
                bottom: 100%;
                width: 100%;
                height: 3px;
                background: linear-gradient($active-color 0 0) calc(100% - var(--p,0%))/ var(--p,0%) no-repeat;
                transition: 0.4s, background-position 0s;
            }
            &::after{
                content: "";
                position: absolute;
                top: 100%;
                width: 100%;
                left: 0;
                height: 3px;
                background: linear-gradient($active-color 0 0) var(--p,0%) / var(--p,0%) no-repeat;
                transition: 0.4s, background-position 0s;
            }
            i{
                color: $active-color;
                font-weight: 600;
            }
        }
    }
}
