.webtools section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
  margin: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.webtools section article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 350px;
  gap: 1rem;
  -webkit-box-shadow: 0 0 13px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 13px rgba(0, 0, 0, 0.6);
}

.webtools section article img {
  width: 100%;
  min-height: 200px;
  padding: .5rem;
  background-color: black;
  -o-object-fit: contain;
     object-fit: contain;
}

.webtools section article img:hover {
  -o-object-fit: scale-down;
     object-fit: scale-down;
}

.webtools section article h3, .webtools section article p {
  margin: 0 0.5rem;
}

.webtools section article h3 span, .webtools section article p span {
  color: #AA1519;
}

.webtools section article a {
  border: 1px solid black;
  border-radius: 1px;
  margin: 1rem;
  padding: 0.5rem;
  color: black;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
}

.webtools section article a:hover {
  border: 1px solid #AA1519;
  color: #AA1519;
}

@media only screen and (max-width: 45em) {
  .webtools section {
    gap: 1rem;
    margin: 0;
  }
  .webtools section article {
    width: 100%;
    gap: 1rem;
  }
  .webtools section article img {
    width: 100%;
    min-height: 200px;
  }
}
