@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Arial;
}

body {
  height: 100vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: auto 1fr auto;
      grid-template-rows: auto 1fr auto;
      grid-template-areas: "header" "main" "footer";
}

main {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: main;
  background-color: #ffffff;
  padding: 1rem;
}
