@charset "UTF-8";
.Errorpage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  margin: 1rem;
}

.Errorpage img {
  width: 100%;
  max-width: 650px;
}

.Errorpage h2 span {
  background-color: black;
  color: #fff;
  padding: 0 0.2rem;
}

.Errorpage div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.Errorpage div a {
  background-color: #ffffff;
  color: black;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  padding: 0.2rem 0;
  margin: 1rem 0;
  cursor: pointer;
}

.Errorpage div a:hover {
  --p: 100%;
}

.Errorpage div a:hover i {
  color: black;
}

.Errorpage div a:hover i::before {
  content: "";
}

.Errorpage div a:first-child i {
  padding: 0 0 0 .4rem;
}

.Errorpage div a:first-child:hover {
  --p: 100%;
}

.Errorpage div a:first-child:hover i {
  padding: 0;
  color: black;
}

.Errorpage div a:first-child:hover i::before {
  content: "";
}

.Errorpage div a::before {
  content: "";
  position: absolute;
  bottom: 100%;
  width: 100%;
  height: 3px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #AA1519)) calc(100% - var(--p,0%))/var(--p, 0%) no-repeat;
  background: linear-gradient(#AA1519 0 0) calc(100% - var(--p,0%))/var(--p, 0%) no-repeat;
  -webkit-transition: 0.4s, background-position 0s;
  transition: 0.4s, background-position 0s;
}

.Errorpage div a::after {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 3px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #AA1519)) var(--p, 0%)/var(--p, 0%) no-repeat;
  background: linear-gradient(#AA1519 0 0) var(--p, 0%)/var(--p, 0%) no-repeat;
  -webkit-transition: 0.4s, background-position 0s;
  transition: 0.4s, background-position 0s;
}

.Errorpage div a i {
  color: #AA1519;
  font-weight: 600;
}
